import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ProfileImg from "../../../assets/images/profile.png";
import { useGetAllPointsQuery, useGetAllUsersWinRatingsQuery } from "../../../services/admin";
import classes from "../../../styles/CheckinFormStyle";
import { StyledDataGrid } from "../../../styles/StyledDataGrid";
import numberWithCommas from "../../../utilities/commafunction";

const columns: GridColDef[] = [

  {
    field: "client",
    headerName: "Client",
    flex: 1,
    renderCell: ({ row }: any) => {
      const image = row?.image;
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            component="img"
            src={image ? image : ProfileImg}
            sx={{
              width: "4.4rem",
              minWidth: "4.4rem",
              height: "4.4rem",
              borderRadius: "1rem",
              objectFit: "cover",
            }}
          />
          <Box>
            <Typography
              className="truncate w-[20rem]"
              sx={{
                fontSize: "1.7rem",
                fontWeight: 600
              }}
            >
              {row?.client}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: "task",
    headerName: "Task",
    flex: 1,
    renderCell: ({ row }: any) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: "1.4rem", fontWeight: 500, lineHeight: 1 }}
            >
              {row?.task}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: "coach",
    headerName: "Coach",
    flex: 1,
    renderCell: ({ row }: any) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Typography sx={{ fontSize: "1.4rem", fontWeight: 500 }}>
            {row?.coach}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "score",
    headerName: "Win Rating",
    flex: 0.5,
    minWidth: 80,
    renderCell: ({ row }: any) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {/* <Box component='img' src={EmailIcon} sx={{width: '3.4rem'}} /> */}
          <Typography sx={{ fontSize: "1.4rem", fontWeight: 500 }}>
            {numberWithCommas(row?.score ? row?.score : row?.winRating)}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: 'action',
    headerName: '',
    minWidth: 50,
    flex: 0.3,
    sortable: false,
    renderCell: (params: any) => {
      return <ManagePointsMenu data={params} activeValue={params?.activeValue} />;
    },
  },
];

export default function PointsTable({ scoreType, activeValue }: any) {
  const [cols, setCols] = React.useState<any>([]);
  const { data, isLoading } = useGetAllPointsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const { data: usersWinRating, isLoading: usersWinRatingIsLoading } = useGetAllUsersWinRatingsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  const normalizeData = (data: any) => {
    let temp = [];
    if (activeValue === 0) {
      temp = usersWinRating?.data?.usersWinRatings?.map((user: any) => {
        return {
          id: user?.id,
          client: user?.full_name,
          coach: user?.coach?.full_name,
          score: user?.winRating,
          winRating: user?.winRating,
          image: user?.profile_photo,
          activeValue: activeValue
        }
      })
    } else {
      temp = data?.map((item: any) => {
        return {
          id: item.id,
          client: item?.user?.full_name,
          task: item.title,
          coach: item?.user?.coach?.full_name,
          winRating: item?.user?.winRating,
          type: item?.type,
          score: item?.score,
          image: item?.user?.profile_photo,
          activeValue: activeValue
        }
      })?.filter((item: any) => item?.type === scoreType);
    }
    return temp?.sort((a: any, b: any) => b?.winRating - a?.winRating);
  };

  const normalizedData = normalizeData(activeValue !== 0 ? (data?.data?.clientScore || []) : usersWinRating?.data?.usersWinRatings) || [];

  const fieldsToShow = ["client", "score", "coach", "action"];

  React.useEffect(() => {
    if (activeValue === 0) {
      setCols(columns.filter((col) => fieldsToShow.includes(col.field)));
    } else {
      setCols(columns);
    }
  }, [activeValue]);

  return (
    <div style={{ height: "64vh", width: "100%", marginTop: "4rem" }}>
      <DataGrid
        rows={normalizedData || []}
        columns={cols}
        pageSize={10}
        rowsPerPageOptions={[10]}
        //checkboxSelection
        sx={StyledDataGrid}
        // disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableExtendRowFullWidth
        disableSelectionOnClick
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Typography
                sx={{ color: "#2165A2", fontSize: "2rem", fontWeight: 600 }}
              >
                {(isLoading || usersWinRatingIsLoading)
                  ? "Loading..."
                  : "No Points Data Found"}
              </Typography>
            </Stack>
          ),
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'score', sort: 'desc' }],
          }
        }}
      />
    </div>
  );
}


const ManagePointsMenu = ({ data }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ minWidth: "33px" }}
      >
        <MoreVertIcon fontSize="large" sx={{ color: "#333333" }} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: "20.3rem"
          }
        }}
      >
        <MenuItem
          sx={classes.menuItems}
          onClick={() => {
            handleClose();
            navigate(`/manage-points/${data?.id}?tab=${data?.row?.activeValue}`)
          }}
        >
          Edit
        </MenuItem>
      </Menu>
    </div>
  )
}
