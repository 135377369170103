import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { isFetchBaseQueryError, useResetAllUsersWinRatingMutation } from "../../../services/admin";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "1px solid #fffff",
  boxShadow: 24,
  p: 1,
  borderRadius: "20px",
  maxWidth: "540px",
  padding: "24px 32px",
  textAlign: "center",
};

export default function ViewModal({ open, setOpen }: any) {
  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: { confirmation: "" }
  });

  const [resetAllUsersWinRating, { isLoading }] = useResetAllUsersWinRatingMutation();

  const handleClose = () => setOpen(false);

  const onSubmit = async () => {
    try {
      await resetAllUsersWinRating({})
        .unwrap()
        .then(() => {
          toast.success("All users Win Rating is reset to 0");
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
    setOpen(false);
    setValue("confirmation", "");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" color="error" gutterBottom>
            Are you sure?
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="inherit" paragraph
              style={{ margin: 0 }}
            >
              Type <span style={{ color: "red", fontWeight: 700, marginBottom: "8px" }}>RESET</span> below to reset all users Win Rating.
            </Typography>
            <Controller
              name="confirmation"
              control={control}
              rules={{
                required: "This field is required.",
                validate: (value) =>
                  value === "RESET" || "You must type RESET."
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  error={!!errors.confirmation}
                  helperText={errors.confirmation?.message}
                  size="small"
                  style={{ margin: "4px 0 0 0" }}
                />
              )}
            />
            <Box display="flex" justifyContent="center" mt={2} gap={2}>
              <Button variant="outlined" color="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                Confirm
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}