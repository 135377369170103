import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Layout from '../../components/Layout/Layout';
import { isFetchBaseQueryError, useGetScorePointsQuery, useUpdateScoreMutation } from '../../services/admin';
import classes from '../../styles/CheckinFormStyle';
import { ClientAntTab, ClientAntTabs } from '../../styles/ResourcesLibraryStyle';
import EditManagePointsUser from './componenets/EditUser';
import PointsTable from './componenets/ManagePointsTable';
import ViewModal from './componenets/ViewModal';

const ManagePoints = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeValue, setActiveValue] = useState(0);
  const [score, setScore] = useState('');
  const scoreType = activeValue === 1 ? "championship" : "action_item"

  const { data, isSuccess } = useGetScorePointsQuery(activeValue, {
    refetchOnMountOrArgChange: true,
  });
  const selectedItem = data?.data?.score?.find((item: any) => item.type === scoreType);

  useEffect(() => {
    if (selectedItem) {
      setScore(selectedItem.score)
    } else {
      setScore('')
    }
  }, [isSuccess, data])

  const [updateScore] = useUpdateScoreMutation();

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setActiveValue(newValue)
  }

  const scoreChangeHandler = (event: any) => {
    const value = event.target.value;
    if (event.charCode >= 48 && event.charCode <= 57 || value < 0) {
      return
    } else {
      setScore(value)
    }
  }

  const submitHandler = async () => {
    if (Number(score) > 1000) {
      toast.error("Invalid Score Value, Max Value 1000");
      return;
    }
    const payload = {
      type: scoreType,
      score: score
    }

    try {
      await updateScore({ payload })
        .unwrap()
        .then(() => {
          toast.success(`Score Updated for ${activeValue === 1 ? "Championship Day" : "Action Item"}`);
        });
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(error.data.message);
      }
    }
  }

  return (
    <Layout>
      <Box sx={classes.headWithBtn}>
        <Stack direction='row' gap='2rem'>
          {showEdit && (
            <IconButton
              sx={classes.backArrow}
              disableRipple
              onClick={() => {
                setShowEdit(false);
              }}>
              <ArrowRightAltIcon />
            </IconButton>
          )}

          <Typography variant='h2'>
            {showEdit ? 'Edit John Doe' : 'Manage Points'}
          </Typography>
        </Stack>

      </Box>
      {showEdit ? (
        <EditManagePointsUser />
      ) : (
        <Box sx={{ marginTop: 1 }}>
          <Stack direction="row" alignItems="end" justifyContent="space-between">
            <Box>
              <ClientAntTabs value={activeValue} onChange={handleChangeTab}>
                <ClientAntTab label="Win Rating" />
                <ClientAntTab label="Championship Day" />
                <ClientAntTab label="Action Item" />
              </ClientAntTabs>
            </Box>
            <Stack flexDirection="row" alignItems="center" gap="14px">
              {
                activeValue !== 0 ?
                  <>
                    <Typography sx={{ color: "#2165A2", fontSize: "16px", fontWeight: 500 }}>
                      Win Rating Per {activeValue === 1 ? "Championship Day" : "Action Item"}
                    </Typography>
                    <Stack flexDirection="row" alignItems="center" gap="6px" sx={{
                      border: "1px solid #2165A2",
                      borderRadius: "4px",
                      padding: "4px"
                    }}>
                      <TextField
                        sx={inputStyle}
                        id="outlined-basic"
                        variant="outlined"
                        type="number"
                        fullWidth
                        placeholder="0"
                        value={score}
                        onChange={scoreChangeHandler}
                      />
                      <Button variant='contained' disableElevation
                        sx={{ fontSize: "10px", width: "40px" }}
                        disabled={Number(score) === selectedItem?.score || Number(score) < 1}
                        onClick={submitHandler}>
                        save
                      </Button>
                    </Stack>
                  </> :
                  <>
                    <Stack flexDirection="row" alignItems="center" gap="14px">
                      <Typography sx={{ color: "#2165A2", fontSize: "16px", fontWeight: 500 }}>
                        Reset all users Win Rating
                      </Typography>
                      <Button variant='contained' disableElevation
                        sx={{ fontSize: "10px", width: "40px" }}
                        onClick={() => setShowModal(true)}>
                        reset
                      </Button>
                    </Stack>
                  </>
              }
            </Stack>
          </Stack>

          <PointsTable setShowEdit={setShowEdit} showEdit={showEdit} scoreType={scoreType} activeValue={activeValue} />
        </Box>
      )}
      <ViewModal open={showModal} setOpen={setShowModal} />
    </Layout>
  );
};

export default ManagePoints;

const inputStyle = {
  //  background: "rgba(162, 163, 172, 0.04);",
  "& .MuiInputBase-input": {
    fontWeight: 600,
    textAlign: "center",
    fontSize: "2rem",
    lineHeight: "2.4rem",
    color: "#2B2E34",
    width: "56px",
    padding: "0.4rem 1.2rem",
    // textTransform: "capitalize",
    // "&.Mui-disabled": {
    //   color: "#2B2E34",
    //   "-webkitTextFillColor": "#2B2E34",
    // },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    // border: "0.1rem solid #A8A8A8",
    border: "none",
    borderRadius: "1.6rem",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "error",
    fontSize: "1.75rem",
  },
}