import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import { useGetAllPointsQuery, useGetAllUsersWinRatingsQuery } from '../../services/admin';
import classes from '../../styles/CheckinFormStyle';
import EditManagePointsUser from './componenets/EditUser';

const ManageUserPoints = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  const { data } = useGetAllPointsQuery(1, {
    refetchOnMountOrArgChange: true,
  });
  const { data: usersWinRatings } = useGetAllUsersWinRatingsQuery(1, {
    refetchOnMountOrArgChange: true,
  });

  let details = [];
  if (tab === "0") {
    details = usersWinRatings?.data?.usersWinRatings;
  } else {
    details = data?.data?.clientScore;
  }

  let selectedItem = details?.find((item: any) => item?.id === id || item?.user?.id === id);

  if (tab === "0") {
    selectedItem = {
      user: {
        id: selectedItem?.id,
        full_name: selectedItem?.full_name,
        profile_photo: selectedItem?.profile_photo,
        winRating: selectedItem?.winRating,
        coach: {
          id: selectedItem?.coach?.id,
          full_name: selectedItem?.coach?.full_name
        }
      }
    }
  }

  return (
    <Layout>
      <Box sx={classes.headWithBtn}>
        <Stack direction='row' gap='2rem'>

          <IconButton
            sx={classes.backArrow}
            disableRipple
            onClick={() => {
              navigate("/manage-points")
            }}>
            <ArrowRightAltIcon />
          </IconButton>

          <Typography variant='h2'>
            Edit {selectedItem?.user?.full_name ? selectedItem?.user?.full_name : "User"}
          </Typography>
        </Stack>

      </Box>
      <EditManagePointsUser data={selectedItem} />
    </Layout>
  );
};

export default ManageUserPoints;
